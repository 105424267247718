<template>
    <div>
        <h4 class="bold text-uppercase">Cài đặt thông báo</h4>
        <div class="row mt-4">
            <div class="col-md-8 col-12">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <h6 class="bold">Tiêu đề thông báo</h6>
                    </div>
                    <div class="col">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập tiêu đề thông báo"
                            required=""
                            v-model="admin_notification.title"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-3 col-12">
                        <h6 class="bold">Nội dung thông báo</h6>
                    </div>
                    <div class="col">
                        <textarea
                            class="form-control textarea-light"
                            rows="10"
                            v-model="admin_notification.content"
                            placeholder="Nhập nội dung cần thông báo"
                        ></textarea>
                        <button
                            @click="updateSiteInfo()"
                            class="mt-3 btn-block btn-lg btn bold btn-dark-blue"
                            type="submit"
                        >
                            Lưu Thông Báo
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0 col-12">
                <div class="card card-red h-100">
                    <div class="card-body">
                        <h4>Lưu ý:</h4>
                        <h6>Thông báo sẽ được hiển ở trang Home của trang.</h6>
                        <h6>Không muốn thông báo bạn hãy <span class="bold">Xóa</span> tiêu đề, nội dung và Lưu.</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "@/api/admin"
export default {
    name: "notification-setting",
    data() {
        return {}
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        admin_notification() {
            let noti = {}
            if (this.site && this.site.admin_notification) {
                try {
                    noti = JSON.parse(this.site.admin_notification)
                } catch (error) {
                    noti = {}
                }
            }
            return noti
        }
    },
    created() {},
    methods: {
        updateSiteInfo: async function() {
            this.site.admin_notification = JSON.stringify(this.admin_notification)
            let data = await updateSiteInfo(this.site)
            if (data.status === 200 && data.success) {
                let siteHost = document.location.host
                this.$swal("Thành Công", data.message, "success")
                await this.$store.dispatch("GET_SITE", siteHost)
            } else this.$swal("Lỗi", data.message, "error")
        }
    }
}
</script>
